export default class Utils {
  constructor() {
    this.setDataBackgrounds();
    this.setDataURLs();
  }

  setDataBackgrounds() {
    if ($('[data-background]')) {
      $('[data-background]').each(function() {
        var attr, that;
        that = $(this);
        attr = that.attr('data-background');
        that.css('background-image', 'url(' + attr + ')');
      });
    }
  }
  setDataURLs() {
    if ($('[data-url-external]')) {
      $('[data-url-external]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url-external');
        that.on('click', function() {
          window.open(url, '_blank');
        });
      });
    }
    if ($('[data-url]')) {
      $('[data-url]').each(function() {
        var url, that;
        that = $(this);
        url = that.attr('data-url');
        that.on('click', function() {
          window.location = url;
        });
      });
    }
  }
}