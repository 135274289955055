export default class Nav {
  constructor() {
    const nav = document.getElementById('home'),
    scroll = document.querySelector('.scroll-to-top');

    if (nav) {
      this.nav = nav;
      this.fixedNav();
    }
    
    if (scroll) {
      this.scrollToTop();
    }
  }
  
  fixedNav() {
    if (this.nav) {
      var stickyScrollPos = 100;
      $(window).on('scroll', function() {
        if ($(window).scrollTop() > stickyScrollPos) {
          $('.sticky').removeClass('fadeIn animated');
          $('.sticky').addClass('sticky-fixed fadeInDown animated');
        }
        else if ($(this).scrollTop() <= stickyScrollPos) {
          $('.sticky').removeClass('sticky-fixed fadeInDown animated');
          $('.sticky').addClass('slideIn animated');
        }
      });
    }
  }
  scrollToTop() {
    $('.scroll-to-top').on('click', function() {
      $('html, body').animate({
        scrollTop: $('html, body').offset().top
      }, 500);
    });
  }
}