'use strict';

// Packages
import 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';

// Sass Style
import '../scss/app.scss';

// Components
import Nav from './components/nav';
import Slider from './components/slider';
import Utils from './utils/utils';

$(function() {
  new Nav();
  new Slider();
  new Utils();
});