export default class Slider {
  constructor() {
    const headerSlider = document.querySelector('.header-slider'),
    sponsorSlider = document.querySelector('.sponsors-slider');

    if (headerSlider) {
      this.initHeaderSlider();
    }
    if (sponsorSlider) {
      this.sponsorSlider();
    }
  }
  initHeaderSlider() {
    $('.header-slider').slick({
      arrows: false,
      dots: true,
      fade: true,
      autoplaySpeed: 5000,
      appendDots: '#dots-container'
    });
  }
  sponsorSlider() {
    $('.sponsors-slider').slick({
      arrows: false,
      autoplay: 1,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
}